<template>
  <div class="main-content">
    <div class="content-block">
      <div class="input-block">
        <div class="input-wrap">
          <input type="text" placeholder="請輸入標題" v-model="title" />
        </div>
        <div class="">
          <div class="input-wrap" style="position:relative">
            <DateRangePicker
              ref="startDate"
              @updateDate="updateDate($event, 'startDate')"
              :time-title="'請選擇開始時間'"
            />
          </div>
        </div>
        <div class="">
          <div class="input-wrap" style="position:relative">
            <DateRangePicker
              ref="endDate"
              @updateDate="updateDate($event, 'endDate')"
              :time-title="'請選擇結束時間'"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-block nofloat">
      <div class="btn-cta" @click="submit">儲存</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DateRangePicker from '@/components/DateRangePicker';
import { createEvent } from '@/api/events';

const moment = require('moment');

export default {
  components: {
    DateRangePicker
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      title: ''
    };
  },
  computed: {
    startDateApi() {
      return moment(this.startDate).format('YYYY-MM-DD HH:mm:ss');
    },

    endDateApi() {
      return moment(this.endDate).format('YYYY-MM-DD HH:mm:ss');
    }
  },

  methods: {
    updateDate(event, name) {
      this[name] = event.startDate;
    },

    validateData({ key, errorText }) {
      console.log(key);
      const data = this[key];

      if (data === '' || data === undefined || data === null) {
        alert(errorText);
        return false;
      }
      return true;
    },

    validate() {
      const rules = [
        {
          key: 'title',
          errorText: '請輸入標題'
        },
        {
          key: 'startDate',
          errorText: '請選擇開始日期'
        },
        {
          key: 'endDate',
          errorText: '請選擇結束日期'
        }
      ];

      for (let rule of rules) {
        console.log(rule);
        let res = this.validateData(rule);

        if (res === false) {
          return res;
        }
      }

      return true;
    },

    createNewEvent() {
      let data = {
        title: this.title,
        start_date: this.startDateApi,
        end_date: this.endDateApi
      };
      console.log(data);
      createEvent(data).then(() => {
        this.$router.push({
          name: 'MemberCalendarDetail',
          params: { time: moment(this.startDate).format('YYYY-MM-DD') }
        });
      });
    },

    submit() {
      if (!this.validate()) {
        return;
      }
      this.createNewEvent();
    }
  }
};
</script>

<style lang="scss" scoped>
.input-wrap {
  margin-bottom: 10px !important;
}
</style>
