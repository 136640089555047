<template>
  <date-range-picker
    ref="picker"
    :opens="opens"
    :locale-data="locale"
    :minDate="minDate"
    :maxDate="maxDate"
    :singleDatePicker="singleDatePicker"
    :timePicker="timePicker"
    :timePicker24Hour="timePicker24Hour"
    :showWeekNumbers="showWeekNumbers"
    :showDropdowns="showDropdowns"
    :autoApply="autoApply"
    v-model="dateRange"
    :ranges="show_ranges ? undefined : false"
    @update="updateValues"
    @toggle="checkOpen"
    :linkedCalendars="linkedCalendars"
    :alwaysShowCalendars="alwaysShowCalendars"
    :append-to-body="appendToBody"
    :closeOnEsc="closeOnEsc"
  >
    <template
      slot="input"
      style="min-width: 350px;"
      :placeholder="timeTitle ? timeTitle : '請選擇結束時間'"
    >
      <input
        class="datetime"
        type="text"
        :placeholder="timeTitle ? timeTitle : '請選擇結束時間'"
        autocomplete="off"
        readonly="readonly"
        style="position:relative"
        v-model="value"
      />
      <!-- {{ dateRange.endDate ? dateRange.endDate : '請選擇結束時間' }} -->
    </template>
  </date-range-picker>
</template>

<script>
const moment = require('moment');

// @ is an alias to /src

export default {
  filters: {
    date(value) {
      if (!value) return '';

      let options = { year: 'numeric', month: 'long', day: 'numeric' };
      return Intl.DateTimeFormat('en-US', options).format(value);
    }
  },
  props: {
    'time-title': {
      type: String
    },
    value: {
      type: String,
      required: false,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      opens: 'right',
      minDate: null,
      maxDate: null,
      autoApply: false,
      dateRange: {},
      show_ranges: false,
      singleDatePicker: true,
      timePicker: true,
      timePicker24Hour: true,
      showDropdowns: true,
      showWeekNumbers: false,
      linkedCalendars: false,
      alwaysShowCalendars: true,
      appendToBody: true,
      closeOnEsc: true,
      ranges: {},
      locale: {
        direction: 'ltr',
        format: 'YYYY-MM-DD hh:mm',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: moment.localeData().firstDayOfWeek()
      }
    };
  },
  created() {
    this.updateValues({
      startDate: this.value,
      endDate: this.value
    });
  },
  computed: {
    pageTitle() {
      return this.$route.meta.pageTitle;
    }
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     window.addEventListener('click', this.closeDate);
  //   });
  // },
  // beforeDestroy() {
  //   window.removeEventListener('click', this.closeDate);
  // },

  methods: {
    openDate() {
      this.$refs.picker.onClickPicker();
    },
    closeDate() {
      this.$refs.picker.clickCancel();
    },
    updateValues(values) {
      if (values.startDate == null || values.endDate == null) {
        return;
      } else {
        this.dateRange.startDate = moment(values.startDate);
        this.dateRange.endDate = moment(values.endDate);

        this.$emit('updateDate', {
          startDate: moment(values.startDate).format('YYYY-MM-DD HH:mm'),
          endDate: moment(values.endDate).format('YYYY-MM-DD HH:mm')
        });
      }
    },
    checkOpen(open, ggg) {
      //
    }
    // dateFormat(classes, date) {
    //   let yesterday = moment().subtract(1, 'day');
    //   if (!classes.disabled) {
    //     classes.disabled = moment(date).isSame(yesterday, 'day');
    //   }
    //   return classes;
    // }
  }
};
</script>

<style lang="scss">
.vue-daterange-picker {
  // position: absolute !important;
  // left: 0;
  // height: 0;
  // width: 0;
  // overflow: hidden;
  width: 100%;
  min-height: 40px;
  &:focus {
    border: 1px solid #50af5e !important;
  }
}
.form-control {
  padding: 0 !important;
  background: #f8f8f8 !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  &:focus {
    border: 1px solid transparent !important;
  }
}
.calendar-time {
  justify-content: center;
}
.daterangepicker {
  select.monthselect {
    border: 1px solid #000;
    border-radius: 3px;
    width: 45% !important;
  }
  input.yearselect {
    border: 1px solid #000;
    border-radius: 2px;
    width: 40% !important;
    height: 23px;
  }
  select.hourselect,
  select.minuteselect,
  select.secondselect {
    margin: 0 !important;
    margin-right: 2.5px !important;
    margin-left: 2.5px !important;
  }
  .calendars.row {
    margin-right: 0;
    margin-left: 0;
  }

  .drp-buttons {
    display: flex !important;
    justify-content: center;
    .btn-success {
      background-color: #337ab7;
      border-color: #2e6da4;
    }
  }
}
</style>
